import React, { ReactElement } from 'react';
import { PageProps } from 'gatsby';
import Title from '@/components/Title';

export default function NotFound(_props: PageProps): ReactElement {
  return (
    <main>
      <Title />
      <p>Sorry, page not found!</p>
    </main>
  );
}
